import { forwardRef, Icon, IconProps } from "@chakra-ui/react";
export default forwardRef<IconProps, "svg">((props, svgRef) => (
  <Icon
    xmlns="http://www.w3.org/2000/svg"
    width={14}
    height={8}
    fill="none"
    viewBox="0 0 14 8"
    ref={svgRef}
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M6.262 7.24a1.044 1.044 0 001.476 0l5-5A1.044 1.044 0 1011.262.762L7 5.025 2.738.763A1.044 1.044 0 001.262 2.24l5 5z"
      clipRule="evenodd"
    />
  </Icon>
));
