import { forwardRef, Icon, IconProps } from "@chakra-ui/react";
export default forwardRef<IconProps, "svg">((props, svgRef) => (
  <Icon
    xmlns="http://www.w3.org/2000/svg"
    width={6}
    height={10}
    fill="none"
    viewBox="0 0 6 10"
    ref={svgRef}
    {...props}
  >
    <path
      fill="currentColor"
      d="M5.468.496a.556.556 0 01.053.772l-.053.055L1.517 5l3.951 3.677c.227.21.244.542.053.772l-.053.055a.663.663 0 01-.829.048l-.059-.048-4.396-4.09a.555.555 0 01-.052-.772l.052-.055L4.58.497a.662.662 0 01.888 0z"
    />
  </Icon>
));
