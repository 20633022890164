import { checkout } from '@imtbl/sdk';
import { Translate } from 'next-translate';
import { toast } from 'sonner';

export type WidgetType = `${checkout.WidgetType}`;

type WidgetListenerMethodMap = (disconnect: () => void) => {
  [key in checkout.WidgetType]: {
    addListeners: (
      widget: checkout.Widget<key>,
      t: Translate,
      orchestrationCallback?: (widgetType: checkout.WidgetType) => void,
    ) => void;
    addCloseListener: (widget: checkout.Widget<key>, callback?: () => void) => void;
    cleanupListeners: (widget: checkout.Widget<key>) => void;
  } | null;
};

export const widgetListenerMethodMap: WidgetListenerMethodMap = disconnect => ({
  [checkout.WidgetType.WALLET]: {
    addListeners: (widget, _, orchestrationCallback) => {
      widget.addListener(checkout.WalletEventType.DISCONNECT_WALLET, () => {
        disconnect();
      });

      widget.addListener(checkout.OrchestrationEventType.REQUEST_SWAP, () =>
        orchestrationCallback?.(checkout.WidgetType.SWAP),
      );

      widget.addListener(checkout.OrchestrationEventType.REQUEST_BRIDGE, () =>
        orchestrationCallback?.(checkout.WidgetType.BRIDGE),
      );

      widget.addListener(checkout.OrchestrationEventType.REQUEST_ONRAMP, () =>
        orchestrationCallback?.(checkout.WidgetType.ONRAMP),
      );
    },
    addCloseListener: (widget, callback) => {
      widget.addListener(checkout.WalletEventType.CLOSE_WIDGET, () => {
        widget.unmount();
        callback?.();
      });
    },
    cleanupListeners: widget => {
      (
        [
          checkout.WalletEventType.CLOSE_WIDGET,
          checkout.WalletEventType.DISCONNECT_WALLET,
          checkout.OrchestrationEventType.REQUEST_SWAP,
          checkout.OrchestrationEventType.REQUEST_BRIDGE,
          checkout.OrchestrationEventType.REQUEST_ONRAMP,
        ] as const
      ).forEach(eventType => widget.removeListener(eventType));
    },
  },

  [checkout.WidgetType.SWAP]: {
    addListeners: (widget, t, orchestrationCallback) => {
      widget.addListener(checkout.SwapEventType.FAILURE, () => {
        toast.error(t('imx-actions.error-generic'));
      });

      widget.addListener(checkout.SwapEventType.REJECTED, () => {
        toast.error(t('imx-actions.error-generic'));
      });

      widget.addListener(checkout.SwapEventType.SUCCESS, () => {
        toast.success(t('imx-actions.swap.success'));
      });

      widget.addListener(checkout.OrchestrationEventType.REQUEST_WALLET, () =>
        orchestrationCallback?.(checkout.WidgetType.WALLET),
      );

      widget.addListener(checkout.OrchestrationEventType.REQUEST_BRIDGE, () =>
        orchestrationCallback?.(checkout.WidgetType.BRIDGE),
      );

      widget.addListener(checkout.OrchestrationEventType.REQUEST_ONRAMP, () =>
        orchestrationCallback?.(checkout.WidgetType.ONRAMP),
      );
    },
    addCloseListener: (widget, callback) => {
      widget.addListener(checkout.SwapEventType.CLOSE_WIDGET, () => {
        widget.unmount();
        callback?.();
      });
    },
    cleanupListeners: widget => {
      (
        [
          checkout.SwapEventType.CLOSE_WIDGET,
          checkout.SwapEventType.FAILURE,
          checkout.SwapEventType.REJECTED,
          checkout.SwapEventType.SUCCESS,
          checkout.OrchestrationEventType.REQUEST_WALLET,
          checkout.OrchestrationEventType.REQUEST_BRIDGE,
          checkout.OrchestrationEventType.REQUEST_ONRAMP,
        ] as const
      ).forEach(eventType => widget.removeListener(eventType));
    },
  },
  [checkout.WidgetType.BRIDGE]: {
    addListeners: (widget, t, orchestrationCallback) => {
      widget.addListener(checkout.BridgeEventType.FAILURE, () => {
        toast.error(t('imx-actions.error-generic'));
      });

      widget.addListener(checkout.BridgeEventType.TRANSACTION_SENT, () => {
        toast.success(t('imx-actions.bridge.success'));
      });

      widget.addListener(checkout.OrchestrationEventType.REQUEST_WALLET, () =>
        orchestrationCallback?.(checkout.WidgetType.WALLET),
      );

      widget.addListener(checkout.OrchestrationEventType.REQUEST_SWAP, () =>
        orchestrationCallback?.(checkout.WidgetType.SWAP),
      );

      widget.addListener(checkout.OrchestrationEventType.REQUEST_ONRAMP, () =>
        orchestrationCallback?.(checkout.WidgetType.ONRAMP),
      );
    },
    addCloseListener: (widget, callback) => {
      widget.addListener(checkout.BridgeEventType.CLOSE_WIDGET, () => {
        widget.unmount();
        callback?.();
      });
    },
    cleanupListeners: widget => {
      (
        [
          checkout.BridgeEventType.CLOSE_WIDGET,
          checkout.BridgeEventType.FAILURE,
          checkout.BridgeEventType.TRANSACTION_SENT,
          checkout.OrchestrationEventType.REQUEST_WALLET,
          checkout.OrchestrationEventType.REQUEST_SWAP,
          checkout.OrchestrationEventType.REQUEST_ONRAMP,
        ] as const
      ).forEach(eventType => widget.removeListener(eventType));
    },
  },
  [checkout.WidgetType.ONRAMP]: {
    addListeners: (widget, t, orchestrationCallback) => {
      widget.addListener(checkout.OnRampEventType.FAILURE, () => {
        toast.error(t('imx-actions.error-generic'));
      });

      widget.addListener(checkout.OnRampEventType.SUCCESS, () => {
        toast.success(t('imx-actions.onramp.success'));
      });

      widget.addListener(checkout.OrchestrationEventType.REQUEST_WALLET, () =>
        orchestrationCallback?.(checkout.WidgetType.WALLET),
      );

      widget.addListener(checkout.OrchestrationEventType.REQUEST_SWAP, () =>
        orchestrationCallback?.(checkout.WidgetType.SWAP),
      );

      widget.addListener(checkout.OrchestrationEventType.REQUEST_BRIDGE, () =>
        orchestrationCallback?.(checkout.WidgetType.BRIDGE),
      );
    },
    addCloseListener: (widget, callback) => {
      widget.addListener(checkout.OnRampEventType.CLOSE_WIDGET, () => {
        widget.unmount();
        callback?.();
      });
    },
    cleanupListeners: widget => {
      (
        [
          checkout.OnRampEventType.CLOSE_WIDGET,
          checkout.OnRampEventType.FAILURE,
          checkout.OnRampEventType.SUCCESS,
          checkout.OrchestrationEventType.REQUEST_WALLET,
          checkout.OrchestrationEventType.REQUEST_SWAP,
          checkout.OrchestrationEventType.REQUEST_BRIDGE,
        ] as const
      ).forEach(eventType => widget.removeListener(eventType));
    },
  },
  [checkout.WidgetType.CONNECT]: null,
  [checkout.WidgetType.SALE]: null,
  [checkout.WidgetType.IMMUTABLE_COMMERCE]: null,
  [checkout.WidgetType.ADD_TOKENS]: null,
  [checkout.WidgetType.PURCHASE]: null,
});
