import { checkout } from '@imtbl/sdk';
import type { Connector } from 'wagmi';
import { immutableZkEvm, immutableZkEvmTestnet } from './chains';

export const getWalletProviderNameFromConnector = (
  connector?: Connector,
): checkout.WalletProviderName => {
  if (connector?.id === 'com.immutable.passport') {
    return checkout.WalletProviderName.PASSPORT;
  }

  if (['io.metamask', 'metaMaskSDK'].includes(connector?.id ?? '')) {
    return checkout.WalletProviderName.METAMASK;
  }

  throw new Error('Unsupported connector');
};

export const isImmutableChain = (chainId = 0) =>
  ([immutableZkEvm.id, immutableZkEvmTestnet.id] as number[]).includes(chainId);
