
    import __i18nConfig from '@next-translate-root/i18n'
    import __appWithI18n from 'next-translate/appWithI18n'
    import Providers from '@/components/Providers';
import { SeoOrganization } from '@/components/features/Seo/SeoOrganization';
import { SeoSearchBox } from '@/components/features/Seo/SeoSearchBox';
import { BaseLayout } from '@/components/features/layout/BaseLayout';
import { AuthenticationModal } from '@/components/modals/AuthenticationModal';
import seoConfig from '@/config/next-seo.config';
import ChainContextProvider from '@/context/ChainContextProvider';
import { useMarketplaceChain } from '@/hooks/use-marketplace-chain';
import { useAuthentication } from '@/hooks/useAuthentication';
import { beamClient } from '@/lib/beam/client';
import { GoogleTagManager } from '@/utils/GoogleTagManager';
import { api } from '@/utils/api';
import { isImmutableChain } from '@/utils/immutable';
import '@public/fonts/fonts.css';
import { HydrationBoundary, QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { setDefaultOptions } from 'date-fns';
import { enUS } from 'date-fns/locale';
import { type Session } from 'next-auth';
import { DefaultSeo } from 'next-seo';
import { type AppType } from 'next/app';
import dynamic from 'next/dynamic';
import { useEffect, useState } from 'react';
import { usePreviousDistinct } from 'react-use';
import '@/lib/beam/client';
import '@/lib/immutable/client';
import '@/styles/globals.css';
import '@public/fonts/fonts.css';
import 'swiper/css';
const CookieConsentModal = dynamic(() => import('@/components/shared/CookieConsentModal').then(mod => mod.CookieConsentModal), {
    ssr: false
});
const App: AppType<{
    session: Session | null;
    dehydratedState: unknown;
}> = ({ Component: Page, pageProps: { session, ...pageProps }, }) => {
    const chain = useMarketplaceChain();
    const previousChainId = usePreviousDistinct(chain.id);
    const { activeConnector, disconnect } = useAuthentication();
    const [queryClient] = useState(() => new QueryClient({
        defaultOptions: {
            queries: {
                // With SSR, we usually want to set some default staleTime
                // above 0 to avoid refetching immediately on the client
                staleTime: 60 * 1000
            }
        }
    }));
    useEffect(() => {
        // Make sure beamClient is set to the correct chain
        if (chain.id !== previousChainId && !isImmutableChain(chain.id)) {
            beamClient.switchChain(chain.id);
        }
        // TODO: when using beam or immutable, disconnect wallet if switching between unsupported chains
    }, [chain.id, previousChainId, activeConnector, chain.supportedConnectors, disconnect]);
    // Sets the locale for all the dates
    setDefaultOptions({ locale: enUS });
    return (<>
      {/* TODO: FaroTracing is currently not working. Turn on when this is fixed  */}
      {/* <FaroTracing /> */}
      <GoogleTagManager />
      <DefaultSeo {...seoConfig}/>

      <ChainContextProvider>
        <QueryClientProvider client={queryClient}>
          <HydrationBoundary state={pageProps.dehydratedState}>
            <Providers session={session}>
              <SeoSearchBox />
              <SeoOrganization />
              <BaseLayout {...pageProps}>
                <Page {...pageProps}/>

                <CookieConsentModal />
                <AuthenticationModal />
              </BaseLayout>
            </Providers>
          </HydrationBoundary>
        </QueryClientProvider>
      </ChainContextProvider>
    </>);
};
const __Next_Translate__Page__194927629e3__ = api.withTRPC(App);

    export default __appWithI18n(__Next_Translate__Page__194927629e3__, {
      ...__i18nConfig,
      isLoader: true,
      skipInitialProps: false,
      loadLocaleFrom: __i18nConfig && __i18nConfig.loadLocaleFrom || ((l, n) => import(`@next-translate-root/locales/${l}/${n}`).then(m => m.default)),
    });
  