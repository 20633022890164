import { Chain } from 'viem';
import { chainConfig as zkChainConfig } from 'viem/zksync';

export const ancient8Testnet = {
  id: 2863311531,
  name: 'Ancient8 Testnet',
  nativeCurrency: { name: 'Ether', symbol: 'ETH', decimals: 18 },
  rpcUrls: {
    default: {
      http: ['https://rpc-testnet.ancient8.gg'],
      webSocket: ['https://rpc-testnet.ancient8.gg'],
    },
    public: {
      http: ['https://rpc-testnet.ancient8.gg'],
      webSocket: ['https://rpc-testnet.ancient8.gg'],
    },
  },
  blockExplorers: {
    etherscan: {
      name: 'a8scan',
      url: 'https://testnet.a8scan.io',
    },
    default: {
      name: 'a8scan',
      url: 'https://testnet.a8scan.io',
    },
  },
} as const satisfies Chain;

export const beam = {
  id: 4337,
  name: 'Beam',
  testnet: false,
  nativeCurrency: {
    decimals: 18,
    name: 'Beam',
    symbol: 'BEAM',
  },
  rpcUrls: {
    public: { http: ['https://build.onbeam.com/rpc'], webSocket: ['wss://build.onbeam.com/ws'] },
    default: { http: ['https://build.onbeam.com/rpc'], webSocket: ['wss://build.onbeam.com/ws'] },
  },
  blockExplorers: {
    default: { name: 'Beam Explorer', url: 'https://subnets.avax.network/beam' },
  },
  contracts: {
    multicall3: {
      address: '0x4956f15efdc3dc16645e90cc356eafa65ffc65ec',
      blockCreated: 1,
    },
  },
} as const satisfies Chain;

export const beamTestnet = {
  id: 13337,
  name: 'Beam Testnet',
  testnet: true,
  nativeCurrency: {
    decimals: 18,
    name: 'Beam',
    symbol: 'BEAM',
  },
  rpcUrls: {
    public: {
      http: ['https://build.onbeam.com/rpc/testnet'],
      webSocket: ['wss://build.onbeam.com/ws/testnet'],
    },
    default: {
      http: ['https://build.onbeam.com/rpc/testnet'],
      webSocket: ['wss://build.onbeam.com/ws/testnet'],
    },
  },
  blockExplorers: {
    default: { name: 'Beam Explorer', url: 'https://subnets-test.avax.network/beam' },
  },
  contracts: {
    multicall3: {
      address: '0x9bf49b704ee2a095b95c1f2d4eb9010510c41c9e',
      blockCreated: 3,
    },
  },
} as const satisfies Chain;

export const immutableZkEvm = {
  id: 13371,
  name: 'Immutable zkEVM',
  testnet: false,
  nativeCurrency: {
    decimals: 18,
    name: 'Immutable Coin',
    symbol: 'IMX',
  },
  rpcUrls: {
    public: { http: ['https://rpc.immutable.com'] },
    default: { http: ['https://rpc.immutable.com'] },
  },
  blockExplorers: {
    etherscan: {
      name: 'Immutable Explorer',
      url: 'https://explorer.immutable.com/',
    },
    default: { name: 'Immutable Explorer', url: 'https://explorer.immutable.com/' },
  },
  contracts: {
    multicall3: {
      address: '0xD51BFa777609213A653a2CD067c9A0132a2D316A',
      blockCreated: 1917421,
    },
  },
} as const satisfies Chain;

export const immutableZkEvmTestnet = {
  id: 13473,
  name: 'Immutable Testnet',
  testnet: true,
  nativeCurrency: {
    decimals: 18,
    name: 'Immutable Coin',
    symbol: 'IMX',
  },
  rpcUrls: {
    public: { http: ['https://rpc.testnet.immutable.com'] },
    default: { http: ['https://rpc.testnet.immutable.com'] },
  },
  blockExplorers: {
    etherscan: {
      name: 'Immutable Testnet Explorer',
      url: 'https://explorer.testnet.immutable.com/',
    },
    default: { name: 'Immutable Testnet Explorer', url: 'https://explorer.testnet.immutable.com/' },
  },
  contracts: {
    multicall3: {
      address: '0x965B104e250648d01d4B3b72BaC751Cde809D29E',
      blockCreated: 3471259,
    },
  },
} as const satisfies Chain;

export const sophonTestnet = {
  ...zkChainConfig,
  id: 531050104,
  name: 'Sophon Testnet',
  nativeCurrency: {
    decimals: 18,
    name: 'Sophon',
    symbol: 'SOPH',
  },
  rpcUrls: {
    default: {
      http: ['https://rpc.testnet.sophon.xyz'],
    },
  },
  blockExplorers: {
    default: {
      name: 'Sophon Testnet Explorer',
      url: 'https://explorer.testnet.sophon.xyz',
    },
  },
  contracts: {
    multicall3: {
      address: '0x83c04d112adedA2C6D9037bb6ecb42E7f0b108Af',
      blockCreated: 15642,
    },
  },
  testnet: true,
} as const satisfies Chain;

export const sophonMainnet = {
  ...zkChainConfig,
  id: 50104,
  name: 'Sophon',
  nativeCurrency: {
    decimals: 18,
    name: 'Sophon',
    symbol: 'SOPH',
  },
  rpcUrls: {
    default: {
      http: ['https://rpc.sophon.xyz'],
    },
  },
  blockExplorers: {
    default: {
      name: 'Sophon Explorer',
      url: 'https://explorer.sophon.xyz',
    },
  },
  contracts: {
    multicall3: {
      address: '0x5f4867441d2416ca88b1b3fd38f21811680cd2c8',
      blockCreated: 116,
    },
  },
} as const satisfies Chain;

export const customChains = {
  ancient8Testnet,
  beam,
  beamTestnet,
  immutableZkEvm,
  immutableZkEvmTestnet,
  sophonTestnet,
  sophonMainnet,
} as const satisfies Record<string, Chain>;
