import { useMarketplaceChain } from '@/hooks/use-marketplace-chain';
import { useAuthentication } from '@/hooks/useAuthentication';
import { useIMXCheckout } from '@/hooks/useIMXCheckout';
import { Box, Flex, IconButton, Spinner, Text, Tooltip, useDisclosure } from '@chakra-ui/react';
import { checkout } from '@imtbl/sdk';
import { Modal } from '@sphere/ui';
import useTranslation from 'next-translate/useTranslation';
import { rem } from 'polished';
import { ReactElement, useCallback, useEffect, useState } from 'react';
import { toast } from 'sonner';
import { useAccount, useSwitchChain } from 'wagmi';
import { useOrchestratedWidget } from './useOrchestratedWidget';
import { widgetListenerMethodMap } from './utils';

// We never expose the immutable commerce widget type to the user, only the widgets that are contained within it
// Hence we need to exclude it from the type for the component to compile
type IMXWidgetType = Exclude<checkout.WidgetType, typeof checkout.WidgetType.IMMUTABLE_COMMERCE>;

type Props<T extends IMXWidgetType> = {
  widgetType: T;
  label: string;
  icon: ReactElement;
};

export const IMXCheckoutAction = <T extends IMXWidgetType>({
  widgetType,
  label,
  icon,
}: Props<T>) => {
  const { t } = useTranslation('common');
  const { isOpen, onClose, onOpen } = useDisclosure();
  const { connect, isConnecting } = useIMXCheckout();
  const { connector } = useAccount();
  const { disconnect } = useAuthentication();
  const [widget, setWidget] = useState<checkout.Widget<T>>();
  const marketplaceChain = useMarketplaceChain();
  const { switchChain } = useSwitchChain();
  const { chain } = useAccount();
  const { orchestratedWidgetType, setOrchestratedWidgetType } = useOrchestratedWidget();

  const modalId = `imx-checkout-modal-${widgetType}`;

  const handleOpen = useCallback(async () => {
    if (chain?.id !== marketplaceChain.id) {
      switchChain
        ? switchChain({ chainId: marketplaceChain.id })
        : toast.error(t('imx-actions.error-chain', { chain: marketplaceChain.name }));
      return;
    }

    onOpen();
    setWidget(undefined);

    try {
      const { checkoutSDK, provider } = await connect();

      const widgets = await checkoutSDK.widgets({
        config: { theme: checkout.WidgetTheme.DARK },
      });

      // Ensure the connected provider is passed to the widget
      const widget = widgets.create(widgetType, { provider });

      widget.mount(modalId);
      setWidget(widget);
    } catch (error) {
      console.log(error);
      toast.error(t('imx-actions.error-generic'));
      onClose();
    }
  }, [
    chain?.id,
    connect,
    marketplaceChain.id,
    marketplaceChain.name,
    modalId,
    onClose,
    onOpen,
    switchChain,
    t,
    widgetType,
  ]);

  const handleClose = useCallback(() => {
    onClose();
    widget?.unmount();
  }, [onClose, widget]);

  useEffect(() => {
    if (!widget) return;

    const { addListeners, addCloseListener, cleanupListeners } =
      widgetListenerMethodMap(disconnect)[widgetType] || {};

    addListeners?.(widget, t, widgetType => {
      setOrchestratedWidgetType(widgetType);
      handleClose();
    });

    addCloseListener?.(widget, onClose);

    return () => {
      widget.unmount();
      cleanupListeners?.(widget);
    };
  }, [handleClose, onClose, setOrchestratedWidgetType, t, widget, widgetType, disconnect]);

  useEffect(() => {
    if (!orchestratedWidgetType || orchestratedWidgetType !== widgetType) return;
    setOrchestratedWidgetType(undefined);
    handleOpen();
  }, [handleOpen, orchestratedWidgetType, setOrchestratedWidgetType, widgetType]);

  const isUnsupportedConnector =
    connector && !marketplaceChain.supportedConnectors.includes(connector.id);

  return (
    <>
      <Flex flexDir="column" align="center" gap="space.6">
        <Tooltip
          label={t('action.unsupported-connector', { connector: connector?.name })}
          placement="top"
          hasArrow
          arrowSize={8}
          isDisabled={!isUnsupportedConnector}
        >
          <IconButton
            variant="secondary"
            onClick={handleOpen}
            aria-label={label}
            icon={icon}
            boxSize={rem(42)}
            isDisabled={!connector?.id || isUnsupportedConnector}
            isLoading={isConnecting && isOpen}
          />
        </Tooltip>
        <Text fontSize="xs">{label}</Text>
      </Flex>
      <Modal
        testId={modalId}
        isOpen={isOpen}
        onClose={handleClose}
        maxW={rem(430)}
        h={rem(650)}
        p={0}
        showCloseButton={false}
      >
        <Flex align="center" justify="center" h={rem(650)} bg="blackBg" rounded="radius.8">
          <Spinner size="lg" color="white" />
        </Flex>
        <Box id={modalId} pos="absolute" inset={0} />
      </Modal>
    </>
  );
};
