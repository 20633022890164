import { forwardRef, Icon, IconProps } from "@chakra-ui/react";
export default forwardRef<IconProps, "svg">((props, svgRef) => (
  <Icon
    xmlns="http://www.w3.org/2000/svg"
    width={20}
    height={20}
    fill="none"
    viewBox="0 0 20 20"
    ref={svgRef}
    {...props}
  >
    <path
      fill="currentColor"
      d="M16.25 5.831a3.544 3.544 0 013.537 3.328l.007.216v6.25a3.544 3.544 0 01-3.328 3.537l-.216.007H3.75A3.544 3.544 0 01.213 15.84l-.007-.216V4.6c.002-.074 0-.15 0-.225A3.544 3.544 0 013.534.838L3.75.83h12.5a1.044 1.044 0 01.13 2.08l-.13.008H3.75a1.456 1.456 0 00-.159 2.904l.159.008h12.5z"
    />
  </Icon>
));
