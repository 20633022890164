import { forwardRef, Icon, IconProps } from "@chakra-ui/react";
export default forwardRef<IconProps, "svg">((props, svgRef) => (
  <Icon
    xmlns="http://www.w3.org/2000/svg"
    width={10}
    height={14}
    fill="none"
    viewBox="0 0 10 14"
    ref={svgRef}
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M4.625.47a.827.827 0 011.17 0l3.963 3.963a.827.827 0 11-1.17 1.17L5.21 2.225 1.832 5.603a.827.827 0 01-1.17-1.17L4.625.47zm1.17 13.06a.827.827 0 01-1.17 0L.662 9.567a.827.827 0 011.17-1.17l3.378 3.378 3.378-3.378a.827.827 0 011.17 1.17L5.795 13.53z"
      clipRule="evenodd"
    />
  </Icon>
));
