const usdcContracts: Record<number, string> = {
  1: '0xA0b86991c6218b36c1d19D4a2e9Eb0cE3606eB48', // mainnet
  11155111: '0x7fc21ceb0c5003576ab5e101eb240c2b822c95d2', // sepolia
  137: '0x2791bca1f2de4661ed88a30c99a7a9449aa84174', // polygon
  80001: '0x0FA8781a83E46826621b3BC094Ea2A0212e71B23', // polygon mumbai
  43114: '0xB97EF9Ef8734C71904D8002F8b6Bc66Dd9c48a6E', // avalanche
  43113: '0x5425890298aed601595a70AB815c96711a31Bc65', // avalanche fuji
  4337: '0x76BF5E7d2Bcb06b1444C0a2742780051D8D0E304', // beam
  13337: '0x007Fdc86FD12924C9116025C7F594843087397E3', // beam testnet
  13371: '0x6de8aCC0D406837030CE4dd28e7c08C5a96a30d2', // immutable zkEvm
  13473: '0x3B2d8A1931736Fc321C24864BceEe981B11c3c57', // immutable zkEvm testnet
  50104: '0x0f5450df41ea983f4de1de52dd9246e6142be411', // sophon
  531050104: '0x155F5f62Ae114a46aF924FAe3a9b994849076403', // sophon testnet
};

export default usdcContracts;
