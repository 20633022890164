import { forwardRef, Icon, IconProps } from "@chakra-ui/react";
export default forwardRef<IconProps, "svg">((props, svgRef) => (
  <Icon
    xmlns="http://www.w3.org/2000/svg"
    width={40}
    height={28}
    fill="none"
    viewBox="0 0 40 28"
    ref={svgRef}
    {...props}
  >
    <path
      fill="currentColor"
      d="M29.52 27.5s-1.232-1.382-2.26-2.604c4.487-1.189 6.199-3.825 6.199-3.825a20.1 20.1 0 01-3.938 1.897 23.662 23.662 0 01-4.966 1.382c-3.288.579-6.302.418-8.87-.032a30.197 30.197 0 01-5.034-1.382 20.721 20.721 0 01-2.5-1.093c-.103-.064-.206-.097-.309-.16a.473.473 0 01-.137-.097 12.146 12.146 0 01-.958-.547s1.643 2.572 5.993 3.793c-1.028 1.222-2.295 2.668-2.295 2.668C2.877 27.275 0 22.614 0 22.614 0 12.264 4.931 3.875 4.931 3.875 9.864.404 14.556.5 14.556.5l.342.386C8.733 2.557 5.89 5.096 5.89 5.096s.754-.385 2.021-.931c3.664-1.511 6.575-1.929 7.774-2.025.205-.033.377-.065.582-.065a30.861 30.861 0 016.918-.064 29.237 29.237 0 0110.308 3.086s-2.705-2.411-8.527-4.082l.48-.515s4.691-.096 9.622 3.375c0 0 4.932 8.39 4.932 18.74 0 0-2.911 4.66-10.48 4.885zM13.597 12.49c-1.952 0-3.493 1.607-3.493 3.567 0 1.961 1.575 3.568 3.493 3.568 1.952 0 3.493-1.607 3.493-3.568a3.5 3.5 0 00-3.493-3.568zm12.5 0c-1.952 0-3.493 1.607-3.493 3.567 0 1.961 1.575 3.568 3.493 3.568 1.952 0 3.493-1.607 3.493-3.568 0-1.96-1.541-3.568-3.493-3.568z"
    />
  </Icon>
));
