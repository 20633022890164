import { forwardRef, Icon, IconProps } from "@chakra-ui/react";
export default forwardRef<IconProps, "svg">((props, svgRef) => (
  <Icon
    xmlns="http://www.w3.org/2000/svg"
    width={20}
    height={19}
    fill="none"
    viewBox="0 0 20 19"
    ref={svgRef}
    {...props}
  >
    <path
      fill="currentColor"
      d="M.049 0L7.77 10.48 0 19h1.749l6.803-7.46L14.049 19H20L11.844 7.93 19.076 0h-1.748l-6.266 6.87L6 0H.05zM2.62 1.308h2.734l12.073 16.384h-2.734L2.62 1.308z"
    />
  </Icon>
));
