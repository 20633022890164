import { forwardRef, Icon, IconProps } from "@chakra-ui/react";
export default forwardRef<IconProps, "svg">((props, svgRef) => (
  <Icon
    xmlns="http://www.w3.org/2000/svg"
    width={20}
    height={20}
    fill="none"
    viewBox="0 0 20 20"
    ref={svgRef}
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M15.319 18.151c.266.192.616.233.925.125a.962.962 0 00.6-.7c.725-3.4 2.483-12.016 3.142-15.116a.655.655 0 00-.217-.633.665.665 0 00-.667-.117C15.61 3.002 4.851 7.043.45 8.668a.695.695 0 00-.45.667c.009.291.209.55.492.633 1.975.592 4.559 1.408 4.559 1.408s1.208 3.658 1.842 5.508a.744.744 0 00.5.484c.241.066.5 0 .675-.175l2.583-2.434s2.976 2.184 4.668 3.384v.008zm-9.176-7.233l1.4 4.616.308-2.925s5.409-4.874 8.493-7.65a.232.232 0 00.025-.316.242.242 0 00-.317-.05c-3.575 2.283-9.91 6.333-9.91 6.333v-.008z"
      clipRule="evenodd"
    />
  </Icon>
));
