import { forwardRef, Icon, IconProps } from "@chakra-ui/react";
export default forwardRef<IconProps, "svg">((props, svgRef) => (
  <Icon
    xmlns="http://www.w3.org/2000/svg"
    width={1045}
    height={1045}
    fill="none"
    viewBox="0 0 1045 1045"
    ref={svgRef}
    {...props}
  >
    <g fill="currentColor" clipPath="url(#medium_svg__medium_svg__clip0_2_4)">
      <path d="M294.772 819.03c162.806 0 294.782-132.76 294.782-296.52S457.568 226 294.772 226 0 358.71 0 522.51c0 163.8 131.966 296.52 294.772 296.52zm470.771-17.4c81.397 0 147.391-124.96 147.391-279.12 0-154.12-65.994-279.12-147.391-279.12-81.398 0-147.391 125-147.391 279.12s65.993 279.12 147.391 279.12zm227.818-29.04c28.629 0 51.839-111.96 51.839-250.08 0-138.11-23.19-250.08-51.829-250.08-28.638 0-51.85 112-51.85 250.08s23.212 250.08 51.84 250.08z" />
    </g>
    <defs>
      <clipPath id="medium_svg__medium_svg__clip0_2_4">
        <path fill="currentColor" d="M0 0h1045v1045H0z" />
      </clipPath>
    </defs>
  </Icon>
));
