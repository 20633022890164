import { forwardRef, Icon, IconProps } from "@chakra-ui/react";
export default forwardRef<IconProps, "svg">((props, svgRef) => (
  <Icon
    xmlns="http://www.w3.org/2000/svg"
    width={20}
    height={20}
    fill="none"
    viewBox="0 0 20 20"
    ref={svgRef}
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M13.468 16.231a1 1 0 01-1.414 0l-4.761-4.76a1 1 0 010-1.415l4.761-4.761a1 1 0 111.414 1.414l-4.054 4.054 4.054 4.054a1 1 0 010 1.414z"
      clipRule="evenodd"
    />
  </Icon>
));
