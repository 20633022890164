import { forwardRef, Icon, IconProps } from "@chakra-ui/react";
export default forwardRef<IconProps, "svg">((props, svgRef) => (
  <Icon
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 12 12"
    ref={svgRef}
    {...props}
  >
    <path
      fill="currentColor"
      d="M11.705.295c.365.366.391.943.078 1.339l-.078.088L7.426 6l4.279 4.278a1.008 1.008 0 01-1.339 1.505l-.088-.078L6 7.426l-4.278 4.279a1.008 1.008 0 01-1.505-1.339l.078-.088L4.574 6 .295 1.722A1.008 1.008 0 011.634.217l.088.078L6 4.574 10.278.295a1.008 1.008 0 011.427 0z"
    />
  </Icon>
));
