import { forwardRef, Icon, IconProps } from "@chakra-ui/react";
export default forwardRef<IconProps, "svg">((props, svgRef) => (
  <Icon
    xmlns="http://www.w3.org/2000/svg"
    width={20}
    height={20}
    fill="none"
    viewBox="0 0 20 20"
    ref={svgRef}
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M9 14A5 5 0 109 4a5 5 0 000 10zm0 2A7 7 0 109 2a7 7 0 000 14z"
      clipRule="evenodd"
    />
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M13.834 12.314l3.851 3.852a1.074 1.074 0 01-1.519 1.52l-3.851-3.853C12 13.5 13.5 12 13.834 12.314z"
      clipRule="evenodd"
    />
  </Icon>
));
