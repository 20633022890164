import { forwardRef, Icon, IconProps } from "@chakra-ui/react";
export default forwardRef<IconProps, "svg">((props, svgRef) => (
  <Icon
    xmlns="http://www.w3.org/2000/svg"
    width={20}
    height={20}
    fill="none"
    viewBox="0 0 20 20"
    ref={svgRef}
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M8.293 4.543a1 1 0 011.414 0l4.761 4.761a1 1 0 010 1.414l-4.76 4.761a1 1 0 11-1.415-1.414l4.054-4.054-4.054-4.054a1 1 0 010-1.414z"
      clipRule="evenodd"
    />
  </Icon>
));
