import { forwardRef, Icon, IconProps } from "@chakra-ui/react";
export default forwardRef<IconProps, "svg">((props, svgRef) => (
  <Icon
    xmlns="http://www.w3.org/2000/svg"
    width={20}
    height={20}
    fill="none"
    viewBox="0 0 20 20"
    ref={svgRef}
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M10 2.71a7.29 7.29 0 100 14.58 7.29 7.29 0 000-14.58zM.623 10a9.377 9.377 0 1118.754 0A9.377 9.377 0 01.623 10zm6.14-3.238a1.044 1.044 0 011.475 0L10 8.524l1.762-1.762a1.044 1.044 0 011.476 1.476L11.476 10l1.762 1.762a1.044 1.044 0 01-1.476 1.476L10 11.476l-1.762 1.762a1.044 1.044 0 01-1.476-1.476L8.524 10 6.762 8.238a1.044 1.044 0 010-1.476z"
      clipRule="evenodd"
    />
  </Icon>
));
